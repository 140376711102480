<template>
    <v-btn
        class="quiz-button px-10"
        x-large
        depressed
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'QuizButton',
        inheritAttrs: false
    }
</script>

<style lang="scss">
    .quiz-button {
        border-radius: 12px !important;
        border: 1px solid #A1A4B1 !important;
		// margin-bottom: 5px;
		// width: 100%;
        height: 48px;
		align-self: center;
        background: none;
        &.px-10 {
            padding-left: 0 !important;
            @media all and (max-width: 768px) {
                padding-left: 24px !important;
            }
        }
        &.theme--light.v-btn.v-btn--has-bg {
            // background: inherit;
        }
        &:disabled {
            border: 1px solid #90949A !important;
            .v-btn__content {
                color: #A9AEB2 !important;
            }
        }
        &.v-btn:not(.v-btn--round).v-size--x-large {
            height: 48px;
        }
        &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
            background: inherit;
        }

        .v-btn__content {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-align: center;
            text-transform: none;
            color: #A1A4B1;
            background: none;
        }

        &.button-fill {
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
            .v-btn__content {
                color: #fff;
            }
        }
        &.button-white {
            background: linear-gradient(270deg, #fff 0%, #fff 100%);
        }
    }
.theme--light.v-btn.v-btn--has-bg {
    background-color: transparent !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
}
</style>
